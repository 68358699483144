import { User } from 'types/User';
import { FileWithPath } from 'react-dropzone';
import { FormikHelpers, FormikValues } from 'formik/dist/types';

// Credits https://dev.to/ankittanna/how-to-create-a-type-for-complex-json-object-in-typescript-d81
export type JSONValue =
  | null
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>;

export const imagesMimeTypes = [
  'image/avif',
  'image/webp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
] as const;

export type ImageMimeType = (typeof imagesMimeTypes)[number];

export type Attachment = {
  id: number;
  entity_name: number;
  entity_id: number;
  url: string;
  uploaded_by: User;
  is_deleted: boolean;
  deleted_at: string | null;
  file_name: string;
  file_type:
    | 'application/pdf'
    | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    | 'application/msword'
    | 'application/vnd.ms-excel'
    | ImageMimeType;
  file_size: number;
  s3_file_id: string;
  date_created: string | null;
  date_modified: string | null;
  tenant_company: number;
  metadata: JSONValue;
};

export type FileAttachment = {
  data: string; // file content or attachment url
} & (
  | (Attachment & {
      type: 'attachment';
      field_name: string;
    })
  | {
      id: string;
      file: FileWithPath;
      type: 'file';
      field_name: string;
    }
);

export type EntityTypeName =
  | 'scenarioitem'
  | 'risk'
  | 'idea'
  | 'issue'
  | 'company'
  | 'bidpackage'
  | 'designmilestone'
  | 'timelineactivity'
  | 'projectsdashboard';

export type SetFieldValue = FormikHelpers<FormikValues>['setFieldValue'];

export type Nullable<T extends Record<string | number, unknown>> = {
  [K in keyof T]: T[K] | null;
};

export type TagType = 'ITEM' | 'BID_PACKAGE';

export type Tag = {
  id: number;
  name: string;
  type: TagType;
};

export type CustomizeViewConfig = {
  enabled: boolean;
  position: number;
};

export type CustomizeViewSettings<T extends string> = Record<
  T,
  CustomizeViewConfig | undefined
>;

export const tenantFeatureFlagsKeys = [
  'advance_filters',
  'procurement_available',
  'TENANT_IS_USING_CUSTOM_TEMPLATE',
  'building_connected_integration_available',
  'building_connected_production',
  'concept_lab',
  'allow_items_import',
  'bid_package_pull_plan_available',
  'extra_decimal_for_markups',
  'procurement_custom_format',
  'calibrate_multiple_components',
  'ediphi_estimates',
  'sage_xml_estimates',
  'public_api',
  'public_api_submit',
  'enable_custom_attributes',
  'export_timeline',
  'decision_analysis_per_component_chart',
  'estimate_ai_assistant',
  'dashboard_ai_assistant',
] as const;

export type TenantFeatureFlagsKeys = (typeof tenantFeatureFlagsKeys)[number];

export type TenantFeatureFlags = Record<TenantFeatureFlagsKeys, boolean>;

export type Pagination = { page: number; pageSize: number };
export type Filters = Record<string, unknown>;

export type ReportColumn = {
  accessor: string;
  title: string;
  is_numeric?: boolean;
  is_date?: boolean;
  is_currency?: boolean;
};
